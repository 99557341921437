import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useShopify } from "~hooks";
import { Button, CartItem } from "~components";
import { MEDIA_QUERIES } from "~utils/css";
import {
  GRID_PADDING_REM,
  GRID_GAP_REM
} from "~components/_common/Grid/index.jsx";

/** ============================================================================
 * @component
 * Global cart.
 */

const Cart = () => {
  // ---------------------------------------------------------------------------
  // graphql

  const { allSanityShopifyProduct } = useStaticQuery(graphql`
    query Cart {
      allSanityShopifyProduct {
        edges {
          node {
            title
            handle

            description

            primaryImage {
              asset {
                gatsbyImageData(
                  width: 512
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }

            gallery {
              asset {
                gatsbyImageData(
                  width: 512
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }

            sourceData {
              id
              title
              handle
              vendor
              description

              images {
                edges {
                  node {
                    originalSrc
                  }
                }
              }
            }

            variants {
              id
              title

              sourceData {
                availableForSale
                id
                sku
                title

                priceV2 {
                  amount
                  currencyCode
                }

                selectedOptions {
                  name
                  value
                }
              }
            }
          }
        }
      }
    }
  `);

  // ---------------------------------------------------------------------------
  // context / ref / state

  const { cart, cartActive, setCartActive, checkout, getProductByVID } =
    useShopify();

  const getCartTotal = () => {
    let total = 0;

    cart.forEach((cartItem) => {
      if (!cartItem) return;

      const product = getProductByVID(
        cartItem?.variantId,
        allSanityShopifyProduct
      );

      const price = parseFloat(product?.variant?.sourceData?.priceV2?.amount);

      if (price) total += price * cartItem?.quantity;
    });

    return total;
  };

  // ---------------------------------------------------------------------------
  // render

  return (
    <div
      css={css`
        opacity: ${cartActive ? `1` : `0`};
        pointer-events: ${cartActive ? `auto` : `none`};
        transform: translate3d(calc(${GRID_PADDING_REM}rem / 2), 0, 0);

        width: calc(100vw - ${GRID_PADDING_REM}rem);
        height: calc(100vh - 5.5rem);
        height: calc(var(--browser-height) - 5.5rem);
        position: fixed;
        z-index: 30;
        background: var(--color-white);
        box-shadow: 0px 2px 4px #0000001a;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        bottom: calc(${GRID_PADDING_REM}rem / 2);

        ${MEDIA_QUERIES?.desktop} {
          width: 392px;
          height: calc(100vh - 7.75rem);
          top: 5.5rem;
          right: 3rem;
          bottom: auto;
          left: auto;
        }
      `}
    >
      <header
        css={css`
          width: 100%;
          height: 4rem;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <h2
          css={css`
            text-transform: uppercase;
            margin-top: 0.3rem;

            ${MEDIA_QUERIES?.desktop} {
              margin-top: 1rem;
            }
          `}
          className="h4"
        >
          Your Bag
        </h2>

        <div
          css={css`
            width: 4rem;
            height: 4rem;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            align-items: center;
            justify-content: center;
            display: none;

            ${MEDIA_QUERIES?.desktop} {
              display: flex;
            }
          `}
        >
          <button
            type="button"
            css={css`
              width: 100%;
              height: 100%;
              position: relative;
              display: block;
            `}
            onClick={() => {
              setCartActive(false);
            }}
          >
            <span
              css={css`
                margin-top: 0.75rem;
              `}
              className="text-cross"
            >
              ×
            </span>
          </button>
        </div>
      </header>

      {(cart?.[0] && (
        <div
          css={css`
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 1rem 1.5rem;
          `}
        >
          <div
            css={css`
              width: 100%;
              height: 1rem;
              position: relative;
              flex: 1 0 auto;
              overflow-y: scroll;
            `}
          >
            <ul>
              {cart?.map((cartItem, cartItemIndex) => {
                const product = getProductByVID(
                  cartItem?.variantId,
                  allSanityShopifyProduct
                );

                if (!product) {
                  return <></>;
                }

                return (
                  <li
                    key={cartItem.variantId}
                    css={css`
                      width: 100%;
                      position: relative;
                    `}
                  >
                    <CartItem
                      item={cartItem}
                      index={cartItemIndex}
                      product={product}
                    />
                  </li>
                );
              })}
            </ul>
          </div>

          <div
            css={css`
              width: 100%;
              position: relative;
              padding: 1.5rem 0;
              border-top: 1px solid black;
              border-bottom: 1px solid black;
            `}
          >
            <div
              css={css`
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
              `}
            >
              <p
                css={css`
                  text-align: center;
                  text-transform: uppercase;
                `}
                className="h4"
              >
                Sub Total
              </p>

              <p
                css={css`
                  text-align: center;
                  text-transform: uppercase;
                `}
                className="b3"
              >
                {`$${getCartTotal()} AUD`}
              </p>
            </div>
          </div>

          <div
            css={css`
              width: 100%;
              position: relative;
              padding-top: 1rem;

              ${MEDIA_QUERIES?.desktop} {
                padding-top: 4rem;
              }
            `}
          >
            <p
              css={css`
                text-align: center;
                margin-bottom: 0.5rem;
              `}
              className="text-button"
            >
              Taxes and shipping calculated at checkout.
            </p>

            <Button
              _css={css`
                width: 100%;
              `}
              border="var(--color-black)"
              onClick={() => {
                checkout();
              }}
              text="Checkout"
            />
          </div>
        </div>
      )) || (
        <div
          css={css`
            width: 100%;
            position: relative;
            flex: 1 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <p className="text-button">Your bag is empty.</p>
        </div>
      )}
    </div>
  );
};

export default Cart;
